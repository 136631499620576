<template>
  <LayoutBuyer
    :sidebarActiveItem="{
      value: 'order',
      children: [
        status === STATUS.QUOTE_APPROVED.value
          ? 'approved'
          : status === STATUS.COMPLETED.value
          ? 'completed'
          : status === STATUS.QUOTE_REJECTED.value
          ? 'rejected'
          : status === STATUS.REQUEST_PENDING.value
          ? 'waiting'
          : 'request',
      ],
    }"
  >
    <template v-slot:title>
      <span v-if="status === STATUS.QUOTE_APPROVED.value">
        Approved Orders
      </span>
      <span v-else-if="status === STATUS.COMPLETED.value">
        Completed Orders
      </span>
      <span v-else-if="status === STATUS.QUOTE_REJECTED.value">
        Rejected Orders
      </span>
      <span v-else-if="status === STATUS.REQUEST_PENDING.value">
        RFQ's Waiting for Admin Approval
      </span>
    </template>
    <div class="order-request p-2 p-md-5 h-100 bg-gray-light-4">
      <AppLoading fillSpace v-if="!orders.current_page"></AppLoading>
      <BuyerOrderTable
        v-else
        :status="status"
        :items="orders"
        :sort="sorts"
        @sort="sortBy"
        @pageChange="pageChange"
      ></BuyerOrderTable>
    </div>
  </LayoutBuyer>
</template>

<script>
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import BuyerOrderTable from '@/components/Partial/Orders/OrderTables/BuyerOrderTable.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LayoutBuyer from '../../../../components/Partial/Layouts/LayoutBuyer/LayoutBuyer.vue';

export default {
  name: 'BuyerQuoteList',

  components: { AppLoading, BuyerOrderTable, LayoutBuyer },

  props: {
    status: { type: String, default: STATUS.QUOTE_APPROVED.value },
  },

  mixins: [_appApiHelper],

  data() {
    return {
      STATUS,
      orders: {},
      sorts: [{ item: 'order_created_at', orderBy: 'DESC' }],
    };
  },

  watch: {
    status() {
      this.fetchOrders();
    },
  },

  methods: {
    async fetchOrders(page = 1) {
      const sorts = {};
      this.sorts.forEach((value) => {
        sorts[value.item] = value.orderBy.toLowerCase();
      });

      const params = {
        page,
        ...sorts,
      };

      if (this.status === STATUS.QUOTE_APPROVED.value) {
        params.approved_only = 1;
      } else if (this.status === STATUS.COMPLETED.value) {
        params.completed_only = 1;
      } else if (this.status === STATUS.QUOTE_REJECTED.value) {
        params.rejected_only = 1;
      }

      this.orders = (
        await Orders.getQuotes({
          params,
        })
      ).data;
    },
    sortBy(sorts) {
      this.sorts = sorts;
      this.fetchOrders();
    },
    async pageChange(page) {
      this.pendingOrders = await this.fetchOrders(page);
    },
  },

  mounted() {
    this.fetchOrders();
  },
};
</script>

<style lang="scss" scoped>
.order-request {
  &-action {
    p {
      // display: none;
      max-width: 0;
      overflow: hidden;
      transition: max-width 0.5s ease-in-out;
      white-space: nowrap;
    }
    &:hover {
      p {
        max-width: 200px;
      }
    }
  }
}
</style>
